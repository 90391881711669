<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <b-card title="Ações">
      <b-row class="my-1 mt-2 d-flex align-items-center">
        <b-col cols="6" class="d-flex">
          <b-form-group class="flex-grow-1">
            <b-form-input
              type="text"
              v-model="search"
              placeholder="Buscar item"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" class="d-flex justify-content-end">
          <b-button
            class="mb-1"
            variant="primary"
            @click="$router.push({ name: 'nova-receita' })"
            block
          >
            Nova Receita +
          </b-button>
        </b-col>
        <b-col>
          <!-- DATA INI -->
          <b-form-group class="mb-2">
            <label for="category" class="font-weight-bold text-primary"
              >Data inicio :</label
            >
            <b-form-input
              id="transactionDate"
              type="date"
              v-model="filters.startDate"
              @change="getAll"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <!-- DATA FIM -->
          <b-form-group class="mb-2">
            <label for="category" class="font-weight-bold text-primary"
              >Data fim :</label
            >
            <b-form-input
              id="transactionDate"
              type="date"
              v-model="filters.endDate"
              @change="getAll"
            ></b-form-input>
          </b-form-group>
        </b-col>
      

      </b-row>

      <b-row>
        <b-col>
          <b-form-group class="mb-2">
            <label for="category" class="font-weight-bold text-primary"
              >Projetos :</label
            ><br />
            <b-form-select
              v-if="projects.length > 0"
              id="projectSelect"
              v-model="selectedProjectId"
              :options="projects"
              value-field="id"
              text-field="name"
              placeholder="Selecione um projeto"
              @change="handleFilterChange"
            ></b-form-select>
            <label>
              <div
                @click="handleShowArquived()"
                :style="{ color: 'blue', padding: '5px 0', cursor: 'pointer' }"
              >
                {{
                  showArquivded ? 'Ocultar arquivados' : 'Mostrar arquivados'
                }}
              </div></label
            >
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group>
            <label for="category" class="font-weight-bold text-primary"
              >Status :</label
            >
            <b-form-select
              v-if="projects.length > 0"
              id="projectSelect"
              v-model="statusOptionSelected"
              :options="statusOptions"
              value-field="value"
              text-field="name"
              placeholder="Selecione um status"
              @change="handleFilterChange"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Receitas de projetos">
      <!-- Tabela principal  -->
      <b-table
        responsive="sm"
        small
        :items="filter()"
        :fields="fields"
        show-empty
        class="p-1 w-100 table-details"
        :per-page="perPage"
        :current-page="0"
      >
        <template #empty>
          <b-alert show variant="primary">
            <p class="text-center">
              <br />
              <strong>Não há dados para exibir</strong>
            </p>
            <hr />
          </b-alert>
        </template>
        <template v-slot:cell(transaction_date)="{ item }">
          {{ dateFormatter(item.transaction_date) }}
        </template>
        <template v-slot:cell(reference_date)="{ item }">
          {{ dateReferenceMonthFormatter(item.reference_date) }}
        </template>
        <template v-slot:cell(value)="{ item }">
          {{ `${numberToMonetary(item.value)}` }}
        </template>
        <template v-slot:cell(project_name)="{ item }">
          <small> {{ item.project_code }} </small>- {{ item.project_name }}
        </template>
        <template v-slot:cell(installments)="{ item }">
          {{ item.installments.length > 0 ? 'Sim' : 'Não'}}
        </template>
        <template v-slot:cell(actions)="{ item }">
          <Actions
            @openModal="openEditModal(item)"
            @openModaDelete="openDeleteModal(item)"
            :item="item"
          />
        </template>
      </b-table>
      <b-pagination
        class="justify-content-center"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        @change="getPage"
        aria-controls="my-table"
      ></b-pagination>
      <div class="mr-2 d-flex justify-content-end">
        <strong>Total de receitas: {{ totalRows }} </strong>
      </div>

      <!-- Modal -->
      <Modal
        :modal="modal"
        :modalForm="modalForm"
        :editable="editable"
        :itemUpdate="itemUpdate"
        @updateList="handleUpdateList"
        @renderList="getAll"
        @closeModal="closeModal()"
      />
    </b-card>
    <b-card title="Recebimento">
      <!-- Tabela dos totais -->
      <b-table
        responsive="sm"
        small
        :items="totals"
        :fields="fieldsTotal"
        show-empty
        class="p-1 w-100"
      >
        <template #empty>
          <b-alert show variant="primary">
            <p class="text-center">
              <br />
              <strong>Não há dados para exibir</strong>
            </p>
            <hr />
          </b-alert>
        </template>
        <template v-slot:cell(totalRealizado)="{ item }">
          {{ `${numberToMonetary(item.totalRealizado)}` }}
        </template>
        <template v-slot:cell(totalPrevisto)="{ item }">
          {{ `${numberToMonetary(item.totalPrevisto)}` }}
        </template>
        <template v-slot:cell(totalGeral)="{ item }">
          {{ `${numberToMonetary(item.totalGeral)}` }}
        </template>
        
      </b-table>
    </b-card>
    <!-- Grafico -->
    <b-card v-if="items && items.length > 0" title="Evolução">
      <div
        class="d-flex justify-content-center align-items-center overflow-scroll-mobile"
      >
        <Chart :chart-options="customOptions" :chart-series="customSeries"/>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BButton,
  BTable,
  BFormInput,
  BFormGroup,
  BCol,
  BFormSelect,
  BAlert,
  BPagination,
} from 'bootstrap-vue';
import { VBTooltip } from 'bootstrap-vue';
import Actions from './components/ActionsRevenue.vue';
import Modal from './components/ModalRevenue.vue';
import Chart from './components/Chart.vue';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BTable,
    BRow,
    BButton,
    Modal,
    Actions,
    BFormInput,
    BFormGroup,
    Chart,
    BCol,
    BFormSelect,
    BAlert,
    BPagination,
  },
  data: () => ({
    modal: false,
    showArquivded: false,
    modalForm: false,
    editable: false,
    itemUpdate: null,
    selectedProjectId: null,
    search: '',
    perPage: 100000,
    currentPage: 1,
    totalRows: 0,
    filterTrigged: false,
    projects: [],
    filters: {
      startDate: null,
      endDate: null,
    },
    statusOptions: [
      {
        value: 'todos',
        name: 'Todos',
      },
      {
        value: 'previsto',
        name: 'Previsto',
      },
      {
        value: 'realizado',
        name: 'Realizado',
      },
    ],
    statusOptionSelected: 'todos',
    fields: [
      {
        key: 'actions',
        label: 'Ações',
        sortable: false,
      },
      {
        key: 'transaction_date',
        label: 'Data',
        sortable: true,
      },
      {
        key: 'value',
        label: 'Valor',
        sortable: false,
      },
      {
        key: 'project_name',
        label: 'Cod - Projeto',
        sortable: false,
      },
      {
        key: 'reference_date',
        label: 'Referência',
        sortable: true,
      },
      {
        key: 'status',
        label: 'Status',
        sortable: true,
      },
      {
        key: 'installments',
        label: 'Parcelamento',
        sortable: true
      }
    ],

    fieldsTotal: [
      {
        key: 'totalPrevisto',
        label: 'Vou receber',
        sortable: false,
      },
      {
        key: 'totalRealizado',
        label: 'Já recebi',
        sortable: false,
      },
      {
        key: 'totalGeral',
        label: 'Total',
        sortable: false,
      },
    ],
    items: [],
    totals: [],
    costsCategoryList: false,
    customOptions: {
      chart: {
        height: 350,
        type: 'line',
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: [],
      },
    },
    customSeries: [
      {
        name: 'Receitas Realizadas',
        data: [],
      },
    ],
  }),

  created() {
    this.getAll();
    this.getProjects();
  },

  computed: {
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
  },

  methods: {
    async getPage(val) {
      this.currentPage = val;
      if (this.filterTrigged) {
        await this.getFilter();
      } else {
        await this.getAll();
      }
    },
    async processChartData(revenues) {
      const monthlyData = {};
      const chartCategories = [];

      revenues.forEach((revenue) => {
        console.log('revenue.reference_date',revenue.transaction_date)
        const month = this.dateReferenceMonthFormatter(revenue.transaction_date);
        if (!monthlyData[month]) {
          monthlyData[month] = 0;
          chartCategories.push(month);
        }
        monthlyData[month] += revenue.value;
      });


      this.customOptions.xaxis.categories = chartCategories;
      this.customSeries[0].data = Object.values(monthlyData);
      this.customOptions.dataLabels = {
        formatter: (val) => ` ${this.numberToMonetary(val)}`,
      };
    },

    async getAll() {
      this.items = [];

      let body = {
        workspace_id: this.$store.getters.currentWorkspace.id,
        archived: this.showArquivded,
        page: this.currentPage,
        perPage: this.perPage,
        start_date: this.filters.startDate ? this.filters.startDate : null,
        end_date: this.filters.endDate ? this.filters.endDate : null,
        project_id: this.selectedProjectId ? this.selectedProjectId : null,
        status: this.statusOptionSelected ? this.statusOptionSelected : null,
      };
      this.$store
        .dispatch('getAllRevenues', body)
        .then((resp) => {
          if (resp) {
            let arrayTranform = [];
            arrayTranform.push(resp.totals);
            this.items = resp.data;
            this.totals = arrayTranform;
            this.processChartData(resp.data);
            this.totalRows = resp.pagination.total;
          }
          console.log(resp)
        })
       
        .catch((err) => {
          console.log(err);
        });
    },
    async getFilter() {
      this.items = [];
      this.totals = [];
      let body = {
        workspace_id: this.$store.getters.currentWorkspace.id,
        archived: this.showArquivded,
        project_id: this.selectedProjectId,
        status: this.statusOptionSelected,
        page: this.currentPage,
        perPage: this.perPage,
        start_date: this.filters.startDate ? this.filters.startDate : null,
        end_date: this.filters.endDate ? this.filters.endDate : null
      };

      this.$store
        .dispatch('getRevenues', body)
        .then((resp) => {
          if (resp) {
            let arrayTranform = [];
            arrayTranform.push(resp.totals);
            this.items = resp.data;
            this.totals = arrayTranform;
            this.processChartData(resp.data);
            this.totalRows = resp.pagination.total;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getProjects() {
      this.$store
        .dispatch('_getProjects', {
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          this.projects = [];
          this.projects.push({
            id: null,
            name: 'Todos',
          });
          resp.data.map((e) => {
            if (this.showArquivded) {
              this.projects.push({
                id: e.id,
                name: `${e.project_code} - ${e.project_name} ${
                  e.is_archived ? '(Arquivado)' : ''
                }`,
              });
            } else {
              if (e.is_archived) {
                return null;
              } else {
                this.projects.push({
                  id: e.id,
                  name: `${e.project_code} - ${e.project_name} ${
                    e.is_archived ? '(Arquivado)' : ''
                  }`,
                });
              }
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // Controladores
    openEditModal(item) {
      this.modal = true;
      this.editable = true;
      this.modalOpenDelete = false;
      this.modalForm = true;
      this.itemUpdate = item.id;
    },
    openDeleteModal(item) {
      this.modal = true;
      this.modalForm = false;
      this.modalOpenDelete = true;
      this.itemUpdate = item.id;
    },
    openModalDefault() {
      this.modal = true;
      this.modalForm = true;
      this.modalOpenDelete = false;
      this.editable = false;
      // this.itemUpdate = item.id
    },
    closeModal() {
      this.modal = false;
      this.editable = false;
    },

    filter() {
      if (this.search.trim() === '') {
        return this.items;
      } else {
        return this.items.filter((item) => {
          const searchText = this.search.toLowerCase();
          return (
            item.project_name.toLowerCase().includes(searchText) ||
            item.project_code.toLowerCase().includes(searchText) ||
            item.description.toLowerCase().includes(searchText) ||
            item.value.toString().toLowerCase().includes(searchText) ||
            item.status.toLowerCase().includes(searchText)
          );
        });
      }
    },

    cleanFilter() {
      this.search = '';
    },

    handleUpdateList() {
      this.getAll();
    },
    handleShowArquived() {
      this.showArquivded = !this.showArquivded;
      this.projects = [];
      this.getAll();
      this.getProjects();
    },
    handleFilterChange() {
      this.getFilter();
      this.filterTrigged = true;
    },
  },
};
</script>

<style>
.overflow-scroll-mobile {
  overflow-x: auto;
  white-space: nowrap;
}
.table-details {
  max-height: 70vh;
  overflow-y: auto;
}
</style>
